<template>
  <Modal
    title="New Version"
    :form="{ data: formData, endpoint: `collections/${sharedCollectionHashid}/personalisations` }"
    confirm-label="Create"
    @confirm="onSuccess"
  >
    <InputGroup label="Name" name="name" description="Example: August 23 Tour">
      <TextInput v-model="formData.name" name="name" />
    </InputGroup>
  </Modal>
</template>

<script setup lang="ts">
import { parseURL } from "ufo";

defineProps<{
  sharedCollectionHashid: string;
}>();

const formData = reactive({
  name: "",
});

const { closeModal } = useModal();

const onSuccess = async (response: { data: LandingPage["personalisations"][number] }) => {
  const pathname = parseURL(response.data.landing_page_url).pathname;
  await navigateTo(pathname);
  closeModal();
};
</script>
